import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css'],
})
export class ImageCardComponent implements OnInit {
  @Input('imageObject') imageObject: {
    id: number;
    image: string;
    overlay: Boolean;
  };

  loading: Boolean = true;

  constructor() {}

  ngOnInit(): void {}

  imageLoaded() {
    this.loading = false;
  }
}
