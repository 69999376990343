import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css'],
})
export class DesignComponent implements OnInit {
  designs: {
    id: number;
    image: string;
    overlay: Boolean;
  }[] = [
    {
      id: 1,
      image: '../../assets/images/design/design1.jpg',
      overlay: true,
    },
    {
      id: 2,
      image: '../../assets/images/design/design2.jpg',
      overlay: false,
    },
    {
      id: 3,
      image: '../../assets/images/design/design3.jpg',
      overlay: false,
    },
    {
      id: 4,
      image: '../../assets/images/design/design4.jpg',
      overlay: false,
    },
    {
      id: 5,
      image: '../../assets/images/design/design5.jpg',
      overlay: false,
    },
    {
      id: 6,
      image: '../../assets/images/design/design6.jpg',
      overlay: false,
    },
    {
      id: 7,
      image: '../../assets/images/design/design7.jpg',
      overlay: true,
    },
    {
      id: 8,
      image: '../../assets/images/design/design8.jpg',
      overlay: false,
    },
    {
      id: 9,
      image: '../../assets/images/design/design9.jpg',
      overlay: true,
    },
    {
      id: 10,
      image: '../../assets/images/design/design10.jpg',
      overlay: false,
    },
    {
      id: 11,
      image: '../../assets/images/design/design11.jpg',
      overlay: false,
    },
    {
      id: 12,
      image: '../../assets/images/design/design12.jpg',
      overlay: false,
    },
    {
      id: 13,
      image: '../../assets/images/design/design13.jpg',
      overlay: false,
    },
    {
      id: 14,
      image: '../../assets/images/design/design14.jpg',
      overlay: false,
    },
    {
      id: 15,
      image: '../../assets/images/design/design15.jpg',
      overlay: true,
    },
    {
      id: 16,
      image: '../../assets/images/design/design16.jpg',
      overlay: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
