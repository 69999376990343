import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css'],
})
export class CollectionComponent implements OnInit {
  collections: {
    id: number;
    image: string;
    heading: string;
    subheading: string;
    description: string;
    overlay: Boolean;
  }[] = [
    {
      id: 1,
      image: '../../assets/images/collection/new-2024-1.jpg',
      heading: 'JUNGLE SAFARI DRESS',
      subheading: 'RAINDROP TUNIC',
      description:
        'THIS CLOUD DANCER COLOUR LINEN DRESS IS INSPIRE FROM JUNGLE SAFARI.',
      overlay: false,
    },
    {
      id: 2,
      image: '../../assets/images/collection/COLLECTION-IMAGE2.jpg',
      heading: 'FORTGARDEN SCARF',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 3,
      image: '../../assets/images/collection/new-2024-2.jpg',
      heading: 'MATISSE SCARF, SUNRISE SCARF AND INDOOR SCARF',
      subheading: '',
      description:
        'WE CREATE THIS THREE SCARVES INSPIRE FROM MATISSE PAINTING, LANDSCAPE PAINTING AND INDOOR PLANT.',
      overlay: false,
    },
    {
      id: 4,
      image: '../../assets/images/collection/new-2024-3.jpg',
      heading: 'KOLKATA SCARF',
      subheading: '',
      description: 'INSPIRE FROM CITY OF JOY KOLKATA.',
      overlay: false,
    },
    {
      id: 5,
      image: '../../assets/images/collection/new-2024-5.jpg',
      heading: 'TIGER SCARF AND TOUCAN SCARF ON SILK',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 6,
      image: '../../assets/images/collection/COLLECTION-IMAGE-TIGER.jpg',
      heading: 'TIGER SCARF',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 7,
      image: '../../assets/images/collection/new-2024-6.jpg',
      heading: 'THREE FRIENDS AND JUNGLE SHIRT ON LINEN FABRIC',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 8,
      image: '../../assets/images/collection/new-2024-7.jpg',
      heading: 'TOUCAN SCARF',
      subheading: '',
      description:
        'THIS COMBINATION IS SHOWING THE CHARM OF CHINTZ AND VIBRANT BEAUTY OF TOUCAN.',
      overlay: false,
    },
    {
      id: 9,
      image: '../../assets/images/collection/COLLECTION-3-IMAGE.jpg',
      heading: '',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 10,
      image: '../../assets/images/collection/new-2024-8.jpg',
      heading: 'TIGER SCARF',
      subheading: '',
      description:
        'THE TIGER SCARF IS STYLISH ACCESSORY, BLENDING FASHION AND BEAUTY OF FEARNESS WITH THIS PRINT.',
      overlay: false,
    },
    {
      id: 11,
      image: '../../assets/images/collection/new-2024-9.jpg',
      heading: 'WE ARE DRESS',
      subheading: 'RACER NECK MIDI DRESS',
      description: 'THIS LEAD CRYSTAL LINEN DRESS SHOW WE ARE ALL TOGETHER.',
      overlay: false,
    },
    {
      id: 12,
      image: '../../assets/images/collection/COLLECTION-11.jpg',
      heading: 'INDOOR SCARF',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 13,
      image: '../../assets/images/collection/new-2024-10.jpg',
      heading:
        'THREE FRIENDS SCARF, JUNGLE SCARF AND MATISSE SCARF ON SILK FABRIC.',
      subheading: '',
      description: '',
      overlay: false,
    },
    {
      id: 14,
      image: '../../assets/images/collection/new-2024-11.jpg',
      heading: 'WE ARE SCARF AND JUNGLE SCARF ON SILK',
      subheading: '',
      description: '',
      overlay: false,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
