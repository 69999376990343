<div
  class="image-card-container"
  data-bs-toggle="modal"
  [attr.data-bs-target]="'#modal' + imageObject.id"
>
  <div [hidden]="loading">
    <img [src]="imageObject.image" class="image-card" (load)="imageLoaded()" />

    <!-- overlay element -->
    <div class="overlay-card" *ngIf="imageObject.overlay">
      <img src="../../assets/images/logo-white.png" class="overlay-image" />
    </div>
  </div>

  <div *ngIf="loading" class="d-flex justify-content-center p-5 border rounded">
    <div
      class="spinner-border text-secondary"
      style="display: flex; align-self: center"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<!-- Modal Start-->
<div
  class="modal modal-lg fade"
  [id]="'modal' + imageObject.id"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div
      class="modal-content"
      style="background-color: #8d8d8d; border: 1px solid #383838"
    >
      <!-- <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close bg-light"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div> -->
      <div class="modal-body border-0">
        <div class="d-flex pb-2">
          <button
            type="button"
            class="btn-close bg-light ms-auto"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <img [src]="imageObject.image" class="w-100 modal-image" />
      </div>
    </div>
  </div>
</div>
<!-- Modal End -->
