import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  gallerys: { id: number; image: string; overlay: Boolean }[] = [
    { id: 1, image: '../../assets/images/gallery/gallery1.jpg', overlay: true },
    {
      id: 2,
      image: '../../assets/images/gallery/gallery2.jpg',
      overlay: false,
    },
    {
      id: 3,
      image: '../../assets/images/gallery/gallery3.jpg',
      overlay: false,
    },
    {
      id: 4,
      image: '../../assets/images/gallery/gallery4.jpg',
      overlay: false,
    },
    {
      id: 5,
      image: '../../assets/images/gallery/gallery5.jpg',
      overlay: false,
    },
    { id: 6, image: '../../assets/images/gallery/gallery6.jpg', overlay: true },
    {
      id: 7,
      image: '../../assets/images/gallery/gallery7.jpg',
      overlay: false,
    },
    {
      id: 8,
      image: '../../assets/images/gallery/gallery8.jpg',
      overlay: false,
    },
    {
      id: 9,
      image: '../../assets/images/gallery/gallery9.jpg',
      overlay: false,
    },
    {
      id: 10,
      image: '../../assets/images/gallery/gallery10.jpg',
      overlay: false,
    },
    {
      id: 11,
      image: '../../assets/images/gallery/gallery11.jpg',
      overlay: true,
    },
    {
      id: 12,
      image: '../../assets/images/gallery/gallery12.jpg',
      overlay: false,
    },
    {
      id: 13,
      image: '../../assets/images/gallery/gallery13.jpg',
      overlay: false,
    },
    {
      id: 14,
      image: '../../assets/images/gallery/gallery14.jpg',
      overlay: false,
    },
    {
      id: 15,
      image: '../../assets/images/gallery/gallery15.jpg',
      overlay: false,
    },
    {
      id: 16,
      image: '../../assets/images/gallery/gallery16.jpg',
      overlay: true,
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
