<!-- Header Start -->
<header>
  <!-- Navbar Start -->
  <nav
    class="navbar navbar-expand-lg navbar-dark bg-dark bg-gradient static-top"
  >
    <div class="container">
      <a class="navbar-brand" routerLink="/">
        <img src="./assets/pic/logo-white.png" alt="Logo" height="36px" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto align-items-center">
          <li class="nav-item">
            <a
              class="nav-link"
              aria-current="page"
              routerLink="/"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              [routerLinkActiveOptions]="{ exact: true }"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/design"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              >Design</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/gallery"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              >Gallery</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/collection"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              >Collection</a
            >
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/bird"
              routerLinkActive="bird-active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
            >
              <img
                src="../../../assets/images/bird logo.png"
                alt="Bird"
                height="36"
                class="bird"
              />
            </a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/about"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              routerLink="/contact-us"
              routerLinkActive="active"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</header>
<!-- Header End -->
