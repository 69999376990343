<div id="bird-background">
  <div class="container" style="min-height: 78vh">
    <!-- Row Start -->
    <div class="row pt-2 pb-2" appDisableRightClick appDisableDrag>
      <!-- *ngFor="let collection of collections" -->

      <!-- Image 1 -->
      <div class="col-12 p-2">
        <app-image-card [imageObject]="collections[0]"></app-image-card>
        <div class="text-center p-2">
          <h4 class="pt-3">{{ collections[0].heading }}</h4>
          <h5>{{ collections[0].subheading }}</h5>
          <p>{{ collections[0].description }}</p>
        </div>
      </div>

      <!-- Image 2 -->
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-6 p-2">
        <app-image-card [imageObject]="collections[1]"></app-image-card>
      </div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 p-2 text-center">
        <h4 class="pt-3">{{ collections[1].heading }}</h4>
        <h5>{{ collections[1].subheading }}</h5>
        <p>{{ collections[1].description }}</p>
      </div>
      <div class="col-12 col-lg-3"></div>

      <!-- Image 3 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[2]"></app-image-card>
        <h4 class="pt-3">{{ collections[2].heading }}</h4>
        <h5>{{ collections[2].subheading }}</h5>
        <p>{{ collections[2].description }}</p>
      </div>

      <!-- Image 4 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[3]"></app-image-card>
        <h4 class="pt-3">{{ collections[3].heading }}</h4>
        <h5>{{ collections[3].subheading }}</h5>
        <p>{{ collections[3].description }}</p>
      </div>

      <!-- Image 5 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[4]"></app-image-card>
        <h4 class="pt-3">{{ collections[4].heading }}</h4>
        <h5>{{ collections[4].subheading }}</h5>
        <p>{{ collections[4].description }}</p>
      </div>

      <!-- Image 6 -->
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-6 p-2">
        <app-image-card [imageObject]="collections[5]"></app-image-card>
      </div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 p-2 text-center">
        <h4 class="pt-3">{{ collections[5].heading }}</h4>
        <h5>{{ collections[5].subheading }}</h5>
        <p>{{ collections[5].description }}</p>
      </div>
      <div class="col-12 col-lg-3"></div>

      <!-- Image 7 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[6]"></app-image-card>
        <h4 class="pt-3">{{ collections[6].heading }}</h4>
        <h5>{{ collections[6].subheading }}</h5>
        <p>{{ collections[6].description }}</p>
      </div>

      <!-- Image 8 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[7]"></app-image-card>
        <h4 class="pt-3">{{ collections[7].heading }}</h4>
        <h5>{{ collections[7].subheading }}</h5>
        <p>{{ collections[7].description }}</p>
      </div>

      <!-- Image 9 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[8]"></app-image-card>
        <h4 class="pt-3">{{ collections[8].heading }}</h4>
        <h5>{{ collections[8].subheading }}</h5>
        <p>{{ collections[8].description }}</p>
      </div>

      <!-- Image 10 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[9]"></app-image-card>
        <h4 class="pt-3">{{ collections[9].heading }}</h4>
        <h5>{{ collections[9].subheading }}</h5>
        <p>{{ collections[9].description }}</p>
      </div>

      <!-- Image 11 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[10]"></app-image-card>
        <h4 class="pt-3">{{ collections[10].heading }}</h4>
        <h5>{{ collections[10].subheading }}</h5>
        <p>{{ collections[10].description }}</p>
      </div>

      <!-- Image 12 -->
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-6 p-2">
        <app-image-card [imageObject]="collections[11]"></app-image-card>
      </div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 col-lg-3"></div>
      <div class="col-12 p-2 text-center">
        <h4 class="pt-3">{{ collections[11].heading }}</h4>
        <h5>{{ collections[11].subheading }}</h5>
        <p>{{ collections[11].description }}</p>
      </div>
      <div class="col-12 col-lg-3"></div>

      <!-- Image 14 -->
      <div class="col-12 p-2 text-center">
        <app-image-card [imageObject]="collections[13]"></app-image-card>
        <h4 class="pt-3">{{ collections[13].heading }}</h4>
        <h5>{{ collections[13].subheading }}</h5>
        <p>{{ collections[13].description }}</p>
      </div>

      <!-- Row End -->

      <!-- <div class="row pt-5" style="min-height: 73vh">
      <div
        class="col-6 offset-2 offset-lg-0 col-lg-3 order-lg-3"
        appDisableRightClick
        appDisableDrag
      >
        <app-image-card [imageObject]="collections[2]"></app-image-card>
        <h6 class="text-end">TOUCAN SCARF</h6>
      </div>

      <div
        class="col-7 order-lg-2 offset-lg-0 col-lg-3 ms-lg-auto mt-lg-auto"
        appDisableRightClick
        appDisableDrag
      >
        <app-image-card [imageObject]="collections[1]"></app-image-card>
        <h6 class="text-end">SKETCHY SCARF</h6>
      </div>

      <div
        class="col-8 offset-3 order-lg-1 offset-lg-0 col-lg-3 mt-lg-5"
        appDisableRightClick
        appDisableDrag
      >
        <app-image-card [imageObject]="collections[0]"></app-image-card>
        <h6>KOLKATA LOVE SCARF</h6>
      </div>
    </div> -->
    </div>
  </div>

  <!-- <div id="bird-background"></div> -->
</div>
