import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  @ViewChild('f', { static: false }) form: NgForm;
  loading: Boolean = false;
  successMessage: String = '';
  errMessage: String = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.invalid) {
      return false;
    }
    console.log('Form:', this.form.value);

    const formValue = { ...this.form.value };

    this.loading = true;
    console.log('Sending Http Request ...');

    this.http
      .post(
        'https://email-sender-abidesign.herokuapp.com/sendmail',
        formValue,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
      .subscribe(
        (response) => {
          console.log(response);
          this.showSuccessMessage();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.showErrorMessage();
        },
        () => {
          console.log('Completed!');
          this.loading = false;
          this.form.reset();
        }
      );
  }

  showSuccessMessage() {
    this.successMessage = 'Message sent successfully';
    setTimeout(() => {
      this.successMessage = '';
    }, 15000);
  }

  showErrorMessage() {
    this.errMessage = 'Something wents wrong!';
    setTimeout(() => {
      this.errMessage = '';
    }, 15000);
  }
}
