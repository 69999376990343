<!--<hr style="width:100%;text-align:left;margin-left:0">-->

<!-- <div class="fixed-bottom" style="height:auto;text-align: center;background-color:grey;">
<div class="">
  <a href="https://www.instagram.com/abidesignstudio/" target="_blank" class="fa fa-instagram fa-lg" ></a> -->
<!--<a href="#" class="fa fa-facebook"></a>-->
<!-- <a href="https://www.linkedin.com/in/abidesign/" target="_blank" class="fa fa-linkedin fa-lg" ></a>
  <a href="https://www.pinterest.ch/abidesignin/" target="_blank" class="fa fa-pinterest fa-lg" ></a>
</div>
  </div> -->

<!-- Footer -->
<footer style="background-color: #454242e9" class="relative-bottom">
  <!-- Grid container -->
  <div class="container">
    <section class="text-center p-2">
      <a
        href="https://www.instagram.com/abidesign2019/"
        target="_blank"
        style="font-size: 20px"
        class="me-3 text-light"
        ><i class="fa fa-instagram" aria-hidden="true"></i
      ></a>
      <a
        href="https://www.pinterest.ch/abidesignin/"
        target="_blank"
        style="font-size: 20px"
        class="me-3 text-light"
        ><i class="fa fa-pinterest-p" aria-hidden="true"></i
      ></a>
      <a
        href="https://www.linkedin.com/in/abidesign19/"
        target="_blank"
        style="font-size: 20px"
        class="me-3 text-light"
        ><i class="fa fa-linkedin" aria-hidden="true"></i
      ></a>
    </section>
  </div>

  <!-- Copyright -->
  <div
    class="text-center text-secondary p-3"
    style="background-color: rgba(0, 0, 0, 0.2)"
  >
    © 2024 Copyright:
    <a class="text-white" routerLink="/">abidesign.in</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
