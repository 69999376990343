<!-- <div
  style="
    background: radial-gradient(
      666px at 0.4% 48%,
      rgb(202, 204, 227) 0%,
      rgb(89, 89, 99) 97.5%
    );
  "
> -->
<div>
  <!-- header-->
  <app-header></app-header>

  <!-- <div class="row" style="padding-top: 80px;">&nbsp;</div> -->
  <!-- routes will be rendered here -->

  <router-outlet></router-outlet>

  <!-- footer -->
  <app-footer></app-footer>
</div>
