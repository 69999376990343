<!-- Container Start -->
<div class="container">
  <!-- Row Start -->
  <div class="row pt-2 pb-2">
    <!-- Column Start -->
    <div
      class="col-6 col-md-3 p-2"
      *ngFor="let design of designs"
      appDisableRightClick
      appDisableDrag
    >
      <app-image-card [imageObject]="design"></app-image-card>
    </div>
    <!-- Column End -->
  </div>
  <!-- Row End -->
</div>
<!-- Container End -->
